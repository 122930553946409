import { cloneDeep } from "lodash";
import { ARTICLE_ACTIONS as actions } from "../actions";
import { defaultPagination, initialState } from "../initialState";
import { createReducer, handleState } from "../utils";

const { article } = initialState;
const clearCurrent = (state) => ({ ...state, current: {} });
const clearList = (state) => ({ ...state, list: [], ...defaultPagination });

const setList = (state, { data }) => {
  const newData = cloneDeep(data);
  delete newData.rows;
  return { ...state, list: data.rows, ...newData };
};

const setCurrent = (state, { data }) => {
  return { ...state, current: data };
};

const actionList = (state, { data, method }) => {
  const newData = handleState(state.tags, cloneDeep(data), method);
  return { ...state, list: data };
};

export const articleReducer = createReducer(article, {
  [actions.CLEAR_CURRENT]: clearCurrent,
  [actions.SET_LIST]: setList,
  [actions.SET_CURRENT]: setCurrent,
  [actions.CUD_LIST]: actionList,
  [actions.CLEAR_LIST]: clearList
});
