export const defaultPagination = {
  total: 0,
  page: 0,
  size: 0,
  pageCount: 0
};
export const initialState = {
  article: {
    list: [],
    ...defaultPagination,
    current: {}
  },
  category: {
    list: [],
    current: {},
    ...defaultPagination
  },
  news: {
    list: [],
    ...defaultPagination,
    current: {}
  },
  subcategory: {
    list: [],
    current: {},
    articleMapSubcategory: [],
    ...defaultPagination
  }
};
