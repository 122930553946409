import { cloneDeep } from "lodash";
import { SUBCATEGORY_ACTIONS as actions } from "../actions";
import { initialState } from "../initialState";
import { createReducer, handleState } from "../utils";

const { subcategory } = initialState;
const clearCurrent = (state) => ({ ...state, current: {} });

const setList = (state, { data }) => {
  const newData = cloneDeep(data);
  const rows = newData.rows.map(({ articles, ...others }) => ({
    ...others,
    articles,
    articleIds: articles.reduce((arr, sub) => {
      const selected = state.articleMapSubcategory.find(({ id }) => id === sub);
      if (selected) arr.push(selected.articles_id);
      return arr;
    }, [])
  }));
  delete newData.rows;
  return { ...state, ...newData, list: rows };
};

const setCurrent = (state, { data }) => {
  return { ...state, current: data };
};

const actionList = (state, { data, method }) => {
  let newData = handleState(state.list, cloneDeep(data), method);
  newData = newData.map(({ articles, ...others }) => ({
    ...others,
    articleIds: articles.map((sub) => {
      const selected = state.articleMapSubcategory.find(({ id }) => id === sub);
      return selected ? selected.articles_id : -1;
    })
  }));
  return { ...state, list: newData };
};

const actionArticleMapSubcategoryList = (state, { data, method }) => {
  const newData = handleState(state.articleMapSubcategory, cloneDeep(data), method);
  return { ...state, articleMapSubcategory: newData };
};

const clearArticleMapSubcategoryList = (state) => {
  return { ...state, articleMapSubcategory: [] };
};

export const subcategoryReducer = createReducer(subcategory, {
  [actions.CLEAR_CURRENT]: clearCurrent,
  [actions.SET_LIST]: setList,
  [actions.SET_CURRENT]: setCurrent,
  [actions.CUD_LIST]: actionList,
  [actions.CLEAR_ARTICLES_MAP_SUBCATEGORY_LIST]: clearArticleMapSubcategoryList,
  [actions.CUD_ARTICLES_MAP_SUBCATEGORY_LIST]: actionArticleMapSubcategoryList
});
