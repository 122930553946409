export const ARTICLE_ACTIONS = {
  CLEAR_CURRENT: "ARTICLE/CLEAR_CURRENT",
  SET_LIST: "ARTICLE/SET_LIST",
  LOGIN: "ARTICLE/LOGIN",
  LOGOUT: "ARTICLE/LOGOUT",
  SET_CURRENT: "ARTICLE/SET_CURRENT",
  CUD_LIST: "ARTICLE/CUD_LIST",
  CUD_CURRENT: "ARTICLE/CUD_CURRENT",
  CLEAR_LIST: "ARTICLE/CLEAR_LIST"
};

export const CATEGORY_ACTIONS = {
  CLEAR_CURRENT: "CATEGORY/CLEAR_CURRENT",
  SET_LIST: "CATEGORY/SET_LIST",
  SET_CURRENT: "CATEGORY/SET_CURRENT",
  CUD_LIST: "CATEGORY/CUD_LIST",
  CUD_CURRENT: "CATEGORY/CUD_CURRENT",
  CLEAR_LIST: "CATEGORY/CLEAR_LIST"
};

export const NEWS_ACTIONS = {
  CLEAR_CURRENT: "NEWS/CLEAR_CURRENT",
  SET_LIST: "NEWS/SET_LIST",
  LOGIN: "NEWS/LOGIN",
  LOGOUT: "NEWS/LOGOUT",
  SET_CURRENT: "NEWS/SET_CURRENT",
  CUD_LIST: "NEWS/CUD_LIST",
  CUD_CURRENT: "NEWS/CUD_CURRENT"
};

export const NEWS_CATEGORY_ACTIONS = {
  CLEAR_CURRENT: "NEWS_CATEGORY/CLEAR_CURRENT",
  SET_LIST: "NEWS_CATEGORY/SET_LIST",
  SET_CURRENT: "NEWS_CATEGORY/SET_CURRENT",
  CUD_LIST: "NEWS_CATEGORY/CUD_LIST",
  CUD_CURRENT: "NEWS_CATEGORY/CUD_CURRENT",
  CLEAR_LIST: "NEWS_CATEGORY/CLEAR_LIST"
};

export const SUBCATEGORY_ACTIONS = {
  CLEAR_CURRENT: "SUBCATEGORY/CLEAR_CURRENT",
  SET_LIST: "SUBCATEGORY/SET_LIST",
  SET_CURRENT: "SUBCATEGORY/SET_CURRENT",
  CUD_LIST: "SUBCATEGORY/CUD_LIST",
  CUD_CURRENT: "SUBCATEGORY/CUD_CURRENT",
  CLEAR_LIST: "SUBCATEGORY/CLEAR_LIST",
  CLEAR_ARTICLES_MAP_SUBCATEGORY_LIST: "ARTICLES_MAP_SUBCATEGORY/CLEAR_LIST",
  CUD_ARTICLES_MAP_SUBCATEGORY_LIST: "ARTICLES_MAP_SUBCATEGORY/CUD_LIST"
};
